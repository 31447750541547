<template>
    <div class="dF fC f1 pb-4 hide-scrollbar" style="overflow-y:scroll">
        <SettingModal />
        <a-card title="Custom Fields">
            <a-table :rowKey="(e) => e.id" :columns="columns" class="white-table" :data-source="customFields">
                <div slot="default" slot-scope="obj">
                    <div class="dF" v-if="typeof obj.defaultAnswer == 'object'">
                        <div class="px-2" :class="ansI != obj.defaultAnswer.length-1 ? 'mr-3' : ''" style="background-color:var(--off-white-dark); border:1px solid #000" v-for="(ans, ansI) in obj.defaultAnswer" :key="ans+ansI">{{ans}}</div>
                    </div>
                    <div v-else>{{obj.defaultAnswer}}</div>
                </div>
                <div slot="date" slot-scope="obj" style="color:#9EA0A5">{{obj.date ? convertDate(obj.date) : ''}}</div>
                <div slot="user" slot-scope="obj">{{obj.user && obj.user.firstName && obj.user.lastName && obj.user.firstName!='' && obj.user.lastName!=''? `${obj.user.firstName} ${obj.user.lastName}`:''}}</div>
                <div slot="type" slot-scope="obj">{{ fieldTypes[obj.type] }}</div>
                <div slot="action" slot-scope="obj" class="dF">
                    <div @click="editField(obj)" class="mr-3" style="cursor:pointer"><img src="@/assets/edit.svg" alt="edit"></div>
                    <div @click="deleteField(obj)" style="cursor:pointer"><img src="@/assets/delete.svg" alt="delete"></div>
                </div>
            </a-table>
            <div @click="addField" style="padding-top:24px; cursor:pointer; color:var(--orange); display:inline-block">
                <div class="dF aC">
                    <a-icon style="font-size:20px" class="mr-3" type="plus-circle" />
                    <div>Add Custom Field</div>
                </div>
            </div>
        </a-card>
    </div>
</template>

<script>
import SettingModal from '@/components/condounits/SettingModal'
export default {
    components:{SettingModal},
    computed:{
		dateFormat(){
			return this.$store.state.condoUnits.allSettings.user && this.$store.state.condoUnits.allSettings.user.options && this.$store.state.condoUnits.allSettings.user.options.regional && this.$store.state.condoUnits.allSettings.user.options.regional.dateFormat ? this.$store.state.condoUnits.allSettings.user.options.regional.dateFormat : 'MM/DD/YYYY'
		},
        customFields() {
            if (this.$store.state.condoUnits.allSettings && this.$store.state.condoUnits.allSettings.app && this.$store.state.condoUnits.allSettings.app.options && this.$store.state.condoUnits.allSettings.app.options.customFields) return this.$store.state.condoUnits.allSettings.app.options.customFields
			return []
        },
		settings(){
			return this.$store.state.condoUnits.allSettings && this.$store.state.condoUnits.allSettings.app ? this.$store.state.condoUnits.allSettings.app.options : {customFields:[], premiums:[], address:{}, addons:{bikeRacks:[], lockers:[], parking:[]}, brokerAccess:{enable:false, exclude:[]}}
		}
    },
    data() {
        return{
            fieldDrawer:{
                visible:false,
                type:'add'
            },
            columns:[
                {
                    dataIndex:"name",
                    key:"name",
                    title:'Name'
                },
                {
                    title:'Field Type',
                    key:'type',
                    slots:{title:'Field Type'},
                    scopedSlots:{customRender:'type'}
                },
                {
                    title:'Default Value',
                    key:'default',
                    slots:{title:'Default Value'},
                    scopedSlots:{customRender:'default'}
                },
                {
                    title:'Date Create',
                    key:'date',
                    slots:{title:'Date Create'},
                    scopedSlots:{customRender:'date'}
                },
                {
                    title:'Modified By',
                    key:'user',
                    slots:{title:'Modified By'},
                    scopedSlots:{customRender:'user'}
                },
                {
                    title:'Actions',
                    key:'action',
                    slots:{title:'Actions'},
                    scopedSlots:{customRender:'action'}
                }
            ],
            newField:{
                name:'',
                type:'',
                multipleAnswer:false,
                defaultAnswer:'',
                options:['', ''],
                id:0,
                date:0
            },
			fieldTypes: {
				'text': 'Text',
				'number': 'Number',
				'checkbox': 'Check Box',
				'multipleChoice': 'Multiple Choice',
				'date': 'Date'
			}
        }
    },
    methods:{
        submit(type) {
            if (type == 'add') return this.createField()
            else if (type == 'edit') return this.updateField()
        },
        updateField() {
            this.$refs.newField.validate(valid => {
                if (valid) {
                    let sendObj = {}
                    if (this.newField.type == 'text') {
                        sendObj.name = this.newField.name
                        sendObj.type = this.newField.type
                        sendObj.defaultAnswer = this.newField.defaultAnswer
                        sendObj.id = this.newField.id
                        sendObj.user = {
                            firstName:this.$store.state.user.user.firstName,
                            lastName:this.$store.state.user.user.lastName,
                            id:this.$store.state.user.user.id
                        }
                        sendObj.date = Date.now()
                    } else if (this.newField.type == 'multipleChoice') {
                        let validSend = true
                        this.newField.options.forEach(x => {
                            if (x == '') {
                                validSend = false
                                return this.$message.error('Please fill in all the options!')
                            }
                        })
                        if (validSend) {
                            sendObj = this.newField
                            sendObj.user = {
                                firstName:this.$store.state.user.user.firstName,
                                lastName:this.$store.state.user.user.lastName,
                                id:this.$store.state.user.user.id
                            }
                            sendObj.id = this.newField.id
                            sendObj.date = Date.now()
                        }
                    }
                    let fields = []
                    if (Object.keys(sendObj).length != 0) {
                        this.customFields.forEach(field => {
                            if (field.id == sendObj.id) {
                                fields.push(sendObj)
                            } else {
                                fields.push(field)
                            }
                        })
                    }

					let settings = JSON.parse(JSON.stringify(this.settings))
					settings.customFields = fields

                    if (fields.length != 0) {
                        this.$api.post(`/settings/:instance/condounits`, {options:settings}).then(({data}) => {
                            this.$store.commit('UPDATE_SETTINGS', data)
                            this.onClose()
                        }).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
                    }
                }
            })
        },
        createField() {
            this.$refs.newField.validate(valid => {
                if (valid) {
                    let sendObj = {}
                    if (this.newField.type == 'text') {
                        sendObj.name = this.newField.name
                        sendObj.type = this.newField.type
                        sendObj.defaultAnswer = this.newField.defaultAnswer
                        sendObj.id = Date.now().toString()
                        sendObj.user = {
                            firstName:this.$store.state.user.user.firstName,
                            lastName:this.$store.state.user.user.lastName,
                            id:this.$store.state.user.user.id
                        }
                        sendObj.date = Date.now()
                    } else if (this.newField.type == 'multipleChoice') {
                        let validSend = true
                        this.newField.options.forEach(x => {
                            if (x == '') {
                                validSend = false
                                return this.$message.error('Please fill in all the options!')
                            }
                        })
                        if (validSend) {
                            sendObj = this.newField
                            sendObj.user = {
                                firstName:this.$store.state.user.user.firstName,
                                lastName:this.$store.state.user.user.lastName,
                                id:this.$store.state.user.user.id
                            }
                            sendObj.id = Date.now().toString()
                            sendObj.date = Date.now()
                        }
                    }
                    if (!this.customFields.length && Object.keys(sendObj).length != 0) {
						let settings = JSON.parse(JSON.stringify(this.settings))
						settings.customFields = [sendObj]
                        this.$api.post(`/settings/:instance/condounits`, {options:settings}).then(({data}) => {
                            this.$store.commit('UPDATE_SETTINGS', data)
                            this.onClose()
                        }).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
                    } else if(this.customFields.length && Object.keys(sendObj).length != 0) {
                        let fields = JSON.parse(JSON.stringify(this.customFields))
                        fields.push(sendObj)
						let settings = JSON.parse(JSON.stringify(this.settings))
						settings.customFields = fields
                        this.$api.post(`/settings/:instance/condounits`, {options:settings}).then(({data}) => {
                            this.$store.commit('UPDATE_SETTINGS', data)
                            this.onClose()
                        }).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
                    }

                }
            })
        },
        deleteField(obj) {
            let self = this

            if (this.$p < 40) return this.$message.error('You do not have permission to delete custom field')

            this.$confirm({
                title: "Delete Custom Field",
                content: h => <div>Do you want to delete this Custom Field?</div>,
                okText: 'DELETE',
                okType: 'danger',
                cancelText: 'CANCEL',
                centered: true,
                onOk() {
                    let fields = JSON.parse(JSON.stringify(self.customFields))
                    let index = fields.findIndex(x => x.id == obj.id)
                    fields.splice(index, 1)

                    let settings = JSON.parse(JSON.stringify(self.settings))

                    settings.customFields = fields
                    self.$api.post(`/settings/:instance/condounits`, {options:settings}).then(({data}) => {
                        self.$store.commit('UPDATE_SETTINGS', data)
                    }).catch(err => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err))
						}
					})
                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        },
        editField(obj) {

            this.$store.commit('EDIT_MODAL', {type:'field', obj:obj})
        },
        filterOption(array) {
            let returnArray = []
            array.forEach(x => {
                if (x != '') {
                    returnArray.push(x)
                }
            })
            return returnArray
        },
        allowMultiple(e) {
            if(e.target.checked) {
                this.newField.defaultAnswer = []
            } else {
                this.newField.defaultAnswer = ''
            }
        },
        addOption() {
            let pushNew = true
            this.newField.options.forEach(x => {
                if (x == '' || !x.trim()) {
                    pushNew = false
                    return this.$message.error('Please fill in all the options!')
                }
            })
            if (pushNew) {
                this.newField.options.push('')
            }
        },
        removeOption(index) {
            this.newField.options.splice(index,1)
        },
        convertDate(num){
			let x = new Date(num)
			//Tue Oct 06 2020 12:11:07 GMT-0400 (Eastern Daylight Time)
			let year = x.getFullYear()
			let month = x.getMonth()+1
			let day = x.getDate()
			if (this.dateFormat == 'YYYY/MM/DD') return year+'/'+month+'/'+day
			else if (this.dateFormat == 'DD/MM/YYYY') return day+'/'+month+'/'+year
			else {
				return month+'/'+day+'/'+year
			}
		},
        req:msg=>({required:true,message:msg}),
        addField(){
            this.$store.commit('OPEN_MODAL', 'field')
        },
        onClose() {
            this.fieldDrawer.visible = false
            this.fieldDrawer.type = 'add'
            this.newField = {
                name:'',
                type:'',
                multipleAnswer:false,
                defaultAnswer:'',
                options:['','']
            }
            this.$refs.newField.resetFields();
        }
    },
}
</script>

<style>
.cancel-button.ant-btn {
    border-color:#ECE9F1 !important;
    background-color:#ECE9F1;
    color:#3F3356
}
</style>
