<template>
    <div class="dF fC f1 pb-4 hide-scrollbar" style="overflow-y: scroll">
        <a-card title="Condo Address">
            <a-form-model class="mt-3" :model="address" ref="newSetup">
                <a-row :gutter="16">
                    <a-col :span="8">
                        <a-form-model-item
                            label="Street Number"
                            prop="streetNumber"
                        >
                            <a-input
                                :min="0"
                                type="number"
                                v-model="address.streetNumber"
                                placeholder="Street Number"
								size="large"
                            ></a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-model-item label="Street Name" prop="street">
                            <a-auto-complete
                                v-model="address.street"
                                placeholder="Street Name"
								size="large"
                            />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-model-item
                            :label="
                                country === 'CA' ? 'Postal Code' : 'Zip Code'
                            "
                            prop="postal"
                        >
                            <a-input
                                v-model="address.postal"
                                :placeholder="
                                    country === 'CA'
                                        ? 'Postal Code'
                                        : 'Zip Code'
                                "
								size="large"
                            ></a-input>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="8">
                        <a-form-model-item label="City" prop="city">
                            <a-input
                                v-model="address.city"
                                placeholder="City"
								size="large"
                            ></a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-model-item
                            :label="country === 'CA' ? 'Province' : 'State'"
                            prop="region"
                        >
                            <a-input
                                v-model="address.region"
                                :placeholder="
                                    country === 'CA' ? 'Province' : 'State'
                                "
								size="large"
                            ></a-input>
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </a-form-model>

            <a-button
                class="pull-right"
                size="large"
                type="primary"
                @click="save"
                :loading="loading"
                >SAVE</a-button
            >
        </a-card>
    </div>
</template>

<script>
export default {
    props: {
        value: Number,
    },
    data: () => ({
        loading: false,
        address: {
            streetNumber: "",
            street: "",
            postal: "",
            city: "",
            region: "",
        },
    }),
    computed: {
        instance() {
            return this.$store.state.instance;
        },
        country() {
            let { country = "" } = this.instance;
            if (!country) country = "";
            country = country.toLowerCase().trim();
            return country === "canada" || country === "ca" ? "CA" : "USA";
        },
        condoAddress() {
            if (
                this.$store.state.condoUnits.allSettings &&
                this.$store.state.condoUnits.allSettings.app &&
                this.$store.state.condoUnits.allSettings.app.options &&
                this.$store.state.condoUnits.allSettings.app.options.address
            ) {
                return this.$store.state.condoUnits.allSettings.app.options
                    .address;
            }
            return {
                streetNumber: "",
                street: "",
                postal: "",
                city: "",
                region: "",
            };
        },
		settings(){
			return this.$store.state.condoUnits.allSettings && this.$store.state.condoUnits.allSettings.app ? this.$store.state.condoUnits.allSettings.app.options : {customFields:[], premiums:[], address:{}, addons:{bikeRacks:[], lockers:[], parking:[]}, brokerAccess:{enable:false, exclude:[]}}
		}
    },
    created() {
        this.address = this.condoAddress;
        this.$api.get(`/settings/:instance/condounits`).then(({ data }) => {
            if (data) {
                this.$store.commit("UPDATE_SETTINGS", data);
                this.address = this.condoAddress;
            }
        }).catch(err => {
			if (!err || !err.response || !err.response.status || err.response.status !== 400) {
				this.$message.error(this.$err(err))
			}
		});
    },

    methods: {
        save() {
            this.loading = true;
			let settings = JSON.parse(JSON.stringify(this.settings))
			settings.address = this.address
			console.log('REMOVING SETTINGS FROM SETTINGS', settings)
            this.$api
                .put(`/settings/:instance/condounits`, {
                    options: settings,
                })
                .then(({data}) => {
                	this.$store.commit("UPDATE_SETTINGS", data);
                    this.$message.success(
                        "Condo address updated successfully."
                    );
                })
                .catch((err) => {
                    console.error("ERROR in adding address details", err);
                    this.$message.success(
                        "Error occurred while updating condo address. Please try again!"
                    );
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>

<style>
</style>
