<template>
	<div class="w-full dF fC f1 pb-4 hide-scrollbar" style="overflow-y:scroll">
		<BHLoading :show="loading" />
		<a-card title="Broker Access Preferences">
			<div>
				<div class="relative">
					Enable Broker Access
					<a-tooltip overlayClassName="change-tooltip-color" class="absolute"
						style="left: 130px; top: -5px;">
						<template slot="title">
							Enabling broker access allows brokers to have access to your unit inventory.
						</template>
						<a-icon type="question-circle" style="line-height: 22.5px; margin-top: 5px; color: var(--orange);" />
					</a-tooltip>
				</div>
				<a-switch class="mt-3" v-model="brokerAccess.enable" />
			</div>

			<div v-if="brokerAccess.enable" class="w-full mt-5">
				<a-row :gutter="16">
					<a-col :span="8">
						<h6>Exclude specific units</h6>
						<a-tree-select
							class="w-full mt-2"
							v-model="brokerAccess.exclude"
							:treeData="units"
							treeCheckable
							:showCheckedStrategy="SHOW_CHILD"
							searchPlaceholder="Please select"
						/>
					</a-col>
				</a-row>
			</div>
			
			<div class="mt-5 dF jE">
				<a-button @click="saveSettings" type="primary">SAVE SETTINGS</a-button>
			</div>
		</a-card>
	</div>
</template>

<script>
import BHLoading from 'bh-mod/components/common/Loading'
import { TreeSelect } from "ant-design-vue";

export default {
	components: { BHLoading },
	data() {
		return {
			loading: false,
			brokerAccess:{
				enable: false,
				exclude:[]
			},
			SHOW_PARENT: TreeSelect.SHOW_PARENT,
		}
	},
	computed: {
		user() {
			return this.$store.state.user.user
		},
		floors(){
			return this.$store.state.condoUnits.floors
		},
		units(){
			let units = this.$store.getters.groupedUnits
			let floors = this.$store.state.condoUnits.floors
			let list = []
			Object.keys(units).forEach(floor => {
				let floorName = floors.find(f => f.id == floor).name;
				let floorObj = {title:floorName, value:floor, key:floor, children:[]}
				units[floor].forEach(unit => {
					let unitName = 'Unit ' + unit.unitNumber + ' - ' + unit.name
					floorObj.children.push({title:unitName, value:unit.id, key:unit.id})
				})
				list.push(floorObj)
			})
			
			return list
		},
		lots() {
            let lotList = [];
            Object.values(this.$store.state.siteplan.lots).forEach((lot) => {
                if (lot.type != "info") lotList.push(lot);
            });
            let list = lotList.map(({ name, id: value, inherit }) => {
                if (inherit && inherit != "") {
                    let lot = {};
                    if (typeof inherit == "object") {
                        lot.title = this.childLots[inherit.id].name;
						
                    } else if (
                        this.childLots[inherit] &&
                        this.childLots[inherit].name
                    ) {
                        lot.title = this.childLots[inherit].name;
                    }
					lot.value = value;
					lot.key = value;
					children = [];
                    return lot;
                } else {
                    return { title:name, value, key: value, children:[] };
                }
            });
            return list;
        },
		settings(){
			return this.$store.state.condoUnits.allSettings && this.$store.state.condoUnits.allSettings.app ? this.$store.state.condoUnits.allSettings.app.options : {customFields:[], premiums:[], address:{}, addons:{bikeRacks:[], lockers:[], parking:[]}, brokerAccess:{enable:false, exclude:[]}}
		}
	},
	methods: {
		saveSettings(){
			console.log('Saving Settings...', this.brokerAccess)
			let sendObj = JSON.parse(JSON.stringify(this.settings))
			sendObj.brokerAccess = this.brokerAccess

			this.$api.post(`/settings/:instance/condounits`, {options:sendObj}).then(({data}) => {
				this.$store.commit('UPDATE_SETTINGS', data)
				this.$message.success('Settings saved successfully!')
			}).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
		}
	},
	created() {
		if (this.settings && this.settings.brokerAccess){
			this.brokerAccess = JSON.parse(JSON.stringify(this.settings.brokerAccess));
		}
	}
}
</script>

<style lang="scss">
	.ant-select-dropdown.ant-select-tree-dropdown.ant-select-dropdown--multiple {
		max-height: 350px !important;
	}
</style>
