<template>
	<div class="dF fC f1 pb-4 hide-scrollbar" style="overflow-y: scroll">
		<ParkingModal />
		<LockerModal />
		<BikeRackModal />
		<bh-loading :show="loading" />
		<a-card>
			<a-tabs class="addOnTab" default-active-key="1">
				<a-tab-pane key="1" tab="Parking Spot">
					<AddonTable :columns="parkingColumns" :data="parking" type="parking" @add="addAddon"
						@edit="editAddon" @copy="copyAddon" @delete="deleteAddon" addText="Add New Parking Spot" />
				</a-tab-pane>
				<a-tab-pane key="2" tab="Locker">
					<AddonTable :columns="lockerColumns" :data="lockers" type="locker" @add="addAddon" @edit="editAddon"
						@copy="copyAddon" @delete="deleteAddon" addText="Add New Locker" />
				</a-tab-pane>
				<a-tab-pane key="3" tab="Bike Rack">
					<AddonTable :columns="bikeColumns" :data="bikeRacks" type="bikeRack" @add="addAddon"
						@edit="editAddon" @copy="copyAddon" @delete="deleteAddon" addText="Add New Bike Rack" />
				</a-tab-pane>
			</a-tabs>
		</a-card>
	</div>
</template>

<script>
	import ParkingModal from "@/components/condounits/ParkingModal";
	import LockerModal from "@/components/condounits/LockerModal";
	import BikeRackModal from "@/components/condounits/BikeRackModal";
	import AddonTable from "@/components/condounits/addonTable";
	import bhLoading from "bh-mod/components/common/Loading";
	import { mapState } from "vuex";

	export default {
		components: { ParkingModal, LockerModal, BikeRackModal, bhLoading, AddonTable },
		computed: {
			...mapState({
				allSettings: (state) => state.condoUnits?.allSettings || {},
				units: (state) => Object.values(state.condoUnits?.units || []),
			}),
			settings() {
				return this.allSettings?.app?.options || {};
			},
			addOns() {
				return this.allSettings?.app?.options?.addons || { parking: [], lockers: [], bikeRacks: [] };
			},
			parking() {
				return this.addOns.parking;
			},
			lockers() {
				return this.addOns.lockers;
			},
			bikeRacks() {
				return this.addOns.bikeRacks;
			},
		},
		data() {
			return {
				loading: false,
				parkingColumns: this.createColumns("Parking"),
				lockerColumns: this.createColumns("Locker"),
				bikeColumns: this.createColumns("Bike Rack"),
				addonTypeMap: {
					parking: 'parking',
					locker: 'lockers',
					bikeRack: 'bikeRacks'
				}
			};
		},
		methods: {
			createColumns(type) {
				let idKey = `${type.charAt(0).toLowerCase()}ID`
				return [
					{ dataIndex: idKey, key: "id", title: `${type} ID`, sorter: (a, b) => this.sortById(a, b, idKey) },
					{ dataIndex: "name", key: "name", title: `${type} #`, sorter: this.sortByName },
					...(type !== "Bike Rack" ? [{ dataIndex: "type", key: "type", title: `${type} Type`, sorter: this.sortByType }] : []),
					{ dataIndex: "price", key: "price", title: `${type} Price`, sorter: (a, b) => a.price - b.price },
					{ dataIndex: "description", key: "description", title: `${type} Description`, sorter: this.sortByDescription },
					{ title: "Status", key: "status", scopedSlots: { customRender: "status" }, sorter: this.sortByStatus },
					{ title: "Unit #", key: "unitId", scopedSlots: { customRender: "unitId" }, sorter: (a, b) => this.sortByUnit(a, b) },
					{ title: "Tag(s)", key: "tags", scopedSlots: { customRender: "tags" } },
					{ title: "Actions", key: "action", scopedSlots: { customRender: "action" } },
				];
			},
			sortById(a, b, idKey) {
				return isNaN(a[idKey]) || isNaN(b[idKey]) ? a[idKey].localeCompare(b[idKey]) : a[idKey] - b[idKey];
			},
			sortByName(a, b) {
				return a.name.localeCompare(b.name, undefined, { numeric: true });
			},
			sortByType(a, b) {
				return a.type.localeCompare(b.type);
			},
			sortByDescription(a, b) {
				return a.description.localeCompare(b.description);
			},
			sortByStatus(a, b) {
				return a.status.localeCompare(b.status);
			},
			sortByUnit(a, b) {
				const unitA = this.unitName(a);
				const unitB = this.unitName(b);
				return (Number(unitA) || 0) - (Number(unitB) || 0);
			},
			unitName(obj) {
				return this.units.find((x) => x.id === obj.unitId)?.unitNumber || "";
			},

			addAddon(type) {
				this.$store.commit("OPEN_MODAL", type);
			},
			editAddon(obj, type) {
				this.$store.commit("EDIT_MODAL", { type: type, obj: obj });
			},
			copyAddon(obj, type) {
				let sendObj = { options: JSON.parse(JSON.stringify(this.settings)), packages: [] };
				let newAddon = JSON.parse(JSON.stringify(obj));
				let unit = this.units.find((x) => x.id == newAddon.unitId);
				if (newAddon.unitId && !unit) {
					return this.$message.error('Unit not found. Please refresh the page and try again.');
				}

				if (unit) {
					unit = JSON.parse(JSON.stringify(unit));
					sendObj.packages = unit.packages;
				}

				newAddon.id = Date.now();
				newAddon[`${type.charAt(0)}ID`] = `${newAddon[`${type.charAt(0)}ID`]} (copy)`;

				let addonTypeKey = this.addonTypeMap[type];

				// Add the new addon to settings and packages
				if (newAddon.unitId) {
					// Check if the package has space for more addons
					if (
						sendObj.packages[0][`max${type.charAt(0).toUpperCase() + type.slice(1)}`] &&
						sendObj.packages[0][`max${type.charAt(0).toUpperCase() + type.slice(1)}`] >
						sendObj.packages[0].other.addons[addonTypeKey].length
					) {
						sendObj.options.addons[addonTypeKey].push(newAddon);
						sendObj.packages[0].other.addons[addonTypeKey].push(newAddon);
					} else {
						return this.$message.error(`The selected unit has reached the maximum number of ${addonTypeKey}.`);
					}
				} else {
					sendObj.options.addons[addonTypeKey].push(newAddon);
				}

				this.sendUpdateRequest(newAddon, unit, sendObj, `Selected addOn copied successfully.`);
			},

			deleteAddon(obj, type) {
				if (this.$p < 20) {
					return this.$message.error("You do not have permission to delete unit add ons");
				}

				this.$confirm({
					title: "Delete Add-On",
					content: (h) => <div>Do you want to delete this Add On?</div>,
					okText: "DELETE",
					okType: "danger",
					cancelText: "CANCEL",
					centered: true,
					onOk: () => {
						let sendObj = { options: {}, packages: [] };
						sendObj.options = JSON.parse(JSON.stringify(this.settings));
						let newAddon = JSON.parse(JSON.stringify(obj));
						let unit = null;
						let packages = [];
						if (newAddon.unitId) {
							unit = this.units.find((x) => x.id == newAddon.unitId)
							if (!unit) {
								return this.$message.error('Unit not found. Please refresh the page and try again.')
							}
							unit = JSON.parse(JSON.stringify(unit));
							packages = unit.packages;
						}

						let addonTypeKey = this.addonTypeMap[type];

						let foundSetting = sendObj.options.addons[addonTypeKey].findIndex((x) => x.id == newAddon.id);
						if (foundSetting != -1) {
							sendObj.options.addons[addonTypeKey].splice(foundSetting, 1);
						}

						if (newAddon.unitId) {
							let foundPackage = packages[0].other.addons[addonTypeKey].findIndex((x) => x.id == newAddon.id);
							if (foundPackage != -1) {
								packages[0].other.addons[addonTypeKey].splice(foundPackage, 1);
							}
						}

						this.sendUpdateRequest(obj, unit, sendObj, "Selected addOn deleted successfully.");
					},
					onCancel() {
						console.log("Cancel");
					},
				});
			},

			sendUpdateRequest(newAddon, unit, sendObj, successMessage) {
				this.loading = true;
				let apiPath = unit
					? `/units/:instance/${newAddon.unitId}/condounits`
					: `/settings/:instance/condounits`;

				this.$api.put(apiPath, { options: sendObj.options, packages: sendObj.packages })
					.then(() => {
						this.$store.commit("UPDATE_SETTINGS", { options: sendObj.options });
						if (unit) {
							this.$store.commit("UPDATE_UNIT", unit);
						}
						this.$message.success(successMessage);
					})
					.catch((err) => {
						console.error("ERROR", err);
					})
					.finally(() => {
						this.loading = false;
					});
			}
		},
	};
</script>

<style>
	.cancel-button.ant-btn {
		border-color: #ece9f1 !important;
		background-color: #ece9f1;
		color: #3f3356;
	}
</style>

<style lang="scss">
	.addOnTab .ant-tabs-nav {
		font-size: 16px;

		.ant-tabs-tab {
			padding-left: 25px;
			padding-right: 25px;
		}
	}

	.addOnTab .ant-tabs-bar {
		margin: 0 0 25px 0;
	}

	.addOnTab .ant-tabs-top-bar {
		border-bottom: none !important;
	}
</style>
